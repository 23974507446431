'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useRef, } from 'react';
import Cookie from '@personly/libs-cookie';
const CookiesContext = createContext(null);
const CookiesProvider = ({ children, cookies, cookieTypes, host }) => {
    const cookie = useRef(new Cookie(host, cookies));
    const types = useMemo(() => cookieTypes
        .filter(({ flags }) => flags.isRequired)
        .map(({ handle }) => handle), [cookieTypes]);
    const getConsent = useCallback(() => {
        const data = cookie.current.getCookie({ types }, 'consent');
        if (data)
            return JSON.parse(data);
        return { types, createdAt: 0 };
    }, [types]);
    const setConsent = useCallback((types) => {
        const consent = { types, createdAt: new Date().getTime() };
        cookie.current.setCookie(consent, 'consent', JSON.stringify(consent));
    }, []);
    const setCookie = useCallback((name, data) => {
        const consent = getConsent();
        cookie.current.setCookie(consent, name, data);
    }, [getConsent]);
    const getCookie = useCallback((name) => {
        const consent = getConsent();
        return cookie.current.getCookie(consent, name);
    }, [getConsent]);
    const value = useMemo(() => ({ setCookie, getCookie, setConsent, getConsent }), [setCookie, getCookie, setConsent, getConsent]);
    return (_jsx(CookiesContext.Provider, { value: value, children: children }));
};
function useCookies() {
    const context = useContext(CookiesContext);
    if (!context)
        throw new Error('useCookies requires a context!');
    return context;
}
export { CookiesProvider, useCookies };
