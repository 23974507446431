'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../icon/index.js';
const ToggleDescription = ({ children, disabled = false, icon, id = null, onClick, }) => {
    const childrenClassnames = ['flex', 'justify-start', 'items-start'];
    const iconClassnames = ['cursor-pointer'];
    if (disabled) {
        childrenClassnames.push('text-input-icon-disabled-color');
        iconClassnames.push('text-input-icon-disabled-color');
    }
    else {
        childrenClassnames.push('text-input-icon-color');
        iconClassnames.push('text-input-icon-color');
    }
    const childrenTestId = ['toggle', 'description', 'children', id]
        .filter((i) => !!i)
        .join('-');
    const childrenIconId = ['toggle', 'description', 'icon', id]
        .filter((i) => !!i)
        .join('-');
    return (_jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: "flex flex-row justify-between", children: [_jsx("div", { "data-testid": childrenTestId, className: childrenClassnames.join(' '), children: children }), _jsx("div", { "data-testid": childrenIconId, onClick: onClick, className: iconClassnames.join(' '), children: _jsx(Icon, { size: "lg", type: "solid", icon: icon }) })] }) }));
};
export default ToggleDescription;
