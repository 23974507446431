'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from 'react';
import { sortMenus } from '@personly/libs-messages';
import { toPathname } from '@personly/libs-pathnames';
import { useLocalizedPathname } from '@personly/libs-hooks';
const MenusContext = createContext(null);
const MenusProvider = ({ children, languages, menu }) => {
    const [isOpen, setIsOpen] = useState(false);
    const localizedPathname = useLocalizedPathname(languages);
    const matchEntry = useCallback((pathname) => menu.find((e) => {
        const currentPathname = localizedPathname(toPathname(e.slug));
        const regexPathname = currentPathname.replaceAll('/', '\\/');
        const regex = new RegExp(`^${regexPathname}(\\/.*)?$`);
        return regex.test(pathname);
    }), [localizedPathname, menu]);
    const makeRoute = useCallback((slug, group, title, active) => {
        const href = localizedPathname(toPathname(slug));
        return { active, group, href, title };
    }, [localizedPathname]);
    const routes = useCallback((pathname) => {
        const matchedEntry = matchEntry(pathname);
        return menu
            .filter((e) => (matchedEntry ? e.group === matchedEntry.group : true))
            .reduce((acc, e) => {
            if (!matchedEntry) {
                const i = acc.findIndex((a) => a.group === e.group);
                if (i === -1)
                    acc.push(makeRoute(e.slug, e.group, e.group, false));
                return acc;
            }
            const active = matchedEntry.slug === e.slug;
            const route = makeRoute(e.slug, e.group, e.title, active);
            return [...acc, route];
        }, [])
            .sort(sortMenus);
    }, [matchEntry, makeRoute, menu]);
    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const breadcrumbs = useCallback((pathname) => {
        const matchedEntry = matchEntry(pathname);
        const result = [{ slug: '/', id: 'HOME' }];
        if (matchedEntry)
            result.push({ slug: matchedEntry.slug, id: matchedEntry.title });
        return result;
    }, [matchEntry]);
    const value = { toggle, isOpen, routes, breadcrumbs };
    return (_jsx(MenusContext.Provider, { value: value, children: children }));
};
function useMenus() {
    const context = useContext(MenusContext);
    if (!context)
        throw new Error('useMenus requires a context!');
    return context;
}
export { MenusProvider, useMenus };
