'use client';
import { jsx as _jsx } from "react/jsx-runtime";
const Button = ({ block = false, children, disabled = false, onClick = null, right = false, type = 'button', }) => {
    const classNames = [
        'border',
        'border-button-border-color',
        'p-1.5',
        'rounded',
        'bg-button-background-color',
        'text-button-text-color',
        'focus:outline-none',
        'active:outline-none',
        'hover:outline-none',
        'disabled:!opacity-60',
        'disabled:!border',
    ];
    if (block)
        classNames.push('w-full');
    if (right)
        classNames.push('float-right');
    const className = classNames.join(' ');
    return (_jsx("button", { type: type, disabled: disabled, onClick: onClick, className: className, children: children }));
};
export default Button;
