'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import Icon from '../icon';
const color = (type) => {
    const classNames = [
        'flex',
        'rounded-tl-lg',
        'rounded-bl-lg',
        'p-2',
        'bg-toast-icon-background-color',
        'self-stretch',
        'items-center',
    ];
    if (type === 'error') {
        classNames.push('text-toast-icon-error-color');
    }
    else if (type === 'success') {
        classNames.push('text-toast-icon-success-color');
    }
    else
        classNames.push('text-toast-icon-notice-color');
    return classNames.join(' ');
};
const icons = {
    error: 'circleExclamation',
    success: 'circleCheck',
    blank: 'triangleExclamation',
    custom: 'triangleExclamation',
    loading: 'triangleExclamation',
};
const icon = (type) => icons[type];
const buttonClassnames = classnames('border-0', 'bg-toast-background-color', 'text-xs', 'justify-end', 'text-toast-dismiss-color', 'm-1');
const Toast = ({ children, dismiss, message, type }) => (_jsxs("div", { className: 'flex flex-row flex-1 items-center', children: [_jsx("div", { className: color(type), children: _jsx(Icon, { icon: icon(type), type: "solid", size: "lg" }) }), _jsxs("div", { className: "flex flex-col flex-1", children: [_jsx("div", { className: "pt-4", children: message }), _jsx("div", { className: "flex flex-1 justify-end", children: _jsx("button", { className: buttonClassnames, onClick: dismiss, children: children }) })] })] }));
export default Toast;
