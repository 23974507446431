'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useMenus } from '@personly/libs-providers';
const MenuLinks = ({ children }) => {
    const { isOpen } = useMenus();
    const classNames = ['hidden', 'flex-col', 'lg:flex'];
    if (isOpen)
        classNames.push('!flex');
    return _jsx("div", { className: classNames.join(' '), children: children });
};
export default MenuLinks;
