import browserCookie from 'js-cookie';

class Cookie {
  constructor(host, cookies) {
    this.host = host;
    this.cookies = cookies;
  }

  setCookie(consent, name, data) {
    const cookie = this.cookies.find(
      (cookie) => cookie.name === name && cookie.domains[this.host]
    );

    if (!cookie) return;

    if (!consent.types.includes(cookie.typeHandle)) return;

    const options = {};
    if (cookie.expires !== 'SESSION') {
      options.expires = parseInt(cookie.expires);
    }

    if (cookie.groupHandle === 'DOMAIN')
      options.domain = cookie.domains[this.host];
    else if (cookie.groupHandle === 'HOST') options.domain = this.host;

    if (options) browserCookie.set(name, data, options);
  }

  getCookie(consent, name) {
    const cookie = this.cookies.find(
      (cookie) => cookie.name === name && cookie.domains[this.host]
    );

    if (!cookie) return null;

    if (!consent.types.includes(cookie.typeHandle)) return null;

    return browserCookie.get(name);
  }
}

export default Cookie;
