'use client';
import { jsx as _jsx } from "react/jsx-runtime";
const generateId = () => (Math.random() + 1).toString(36).substring(7);
const className = (disabled) => disabled
    ? 'accent-input-border-color opacity-45'
    : 'accent-input-border-color';
const Checkbox = ({ disabled = false, onChange, checked }) => (_jsx("input", { disabled: disabled, id: generateId(), className: className(disabled), checked: checked, type: "checkbox", onChange: (e) => {
        onChange(e.currentTarget.checked);
    } }));
export default Checkbox;
