'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import { useMenus } from '@personly/libs-providers';
const className = classnames('border-0', 'inline-block', 'align-middle', 'text-menu-link-color', 'bg-menu-background-color');
const MenuTogglerButton = ({ children }) => {
    const { toggle } = useMenus();
    return (_jsx("button", { "aria-label": "Toggle Nav", onClick: toggle, type: "button", className: className, children: children }));
};
export default MenuTogglerButton;
