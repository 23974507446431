'use client';
import { jsx as _jsx } from "react/jsx-runtime";
const Upload = ({ children, disabled }) => {
    const classNames = [
        'border-dashed',
        'border-4',
        'border-upload-color',
        'text-upload-color',
        '!p-8',
        'text-center',
        'cursor-pointer',
        'bg-card-background-color',
    ];
    if (disabled) {
        classNames.push('border-upload-disabled-color');
        classNames.push('text-upload-disabled-color');
        classNames.push('cursor-auto');
    }
    return _jsx("div", { className: classNames.join(' '), children: children });
};
export default Upload;
